import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import TopBar from "../../components/TopBar";
import axios from "axios";
import "./Categories.css";
import Footer from "../../components/Footer";

const BASE_IMAGE_URL_PUBLIC = "https://admin.aristoc.co.ug:9443/product/";
const BASE_IMAGE_URL_STORAGE =
  "https://admin.aristoc.co.ug:9443/storage/product/";

const renderStars = (rating) => {
  const fullStars = Math.floor(rating);
  const halfStars = rating % 1 !== 0 ? 1 : 0;
  const emptyStars = 5 - fullStars - halfStars;

  return (
    <div className="star-rating">
      {[...Array(fullStars)].map((_, index) => (
        <span key={index} className="full-star">
          ★
        </span>
      ))}
      {halfStars === 1 && <span className="half-star">✩</span>}
      {[...Array(emptyStars)].map((_, index) => (
        <span key={index} className="empty-star">
          ☆
        </span>
      ))}
    </div>
  );
};

export default function Categories() {
  const location = useLocation();
  const categoryId = location.state?.categoryId;
  const [visibleProducts, setVisibleProducts] = useState(14);
  const [categoryName, setCategoryName] = useState("");
  const [showSubcategories, setShowSubcategories] = useState(true);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [categories, setCategories] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [expandedCategories, setExpandedCategories] = useState({});
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [products, setProducts] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [loadingSubcategories, setLoadingSubcategories] = useState(false);
  const navigate = useNavigate();
  const [checkedCategory, setCheckedCategory] = useState(null);
  const CACHE_DURATION = 60 * 60 * 1000; // Cache duration in milliseconds (1 hour)

  const fetchWithCacheAndRetry = async (
    url,
    cacheKey,
    retries = 3,
    delay = 2000
  ) => {
    const cachedData = localStorage.getItem(cacheKey);
    const cachedTimestamp = localStorage.getItem(`${cacheKey}_timestamp`);

    if (
      cachedData &&
      cachedTimestamp &&
      Date.now() - cachedTimestamp < CACHE_DURATION
    ) {
      return JSON.parse(cachedData);
    }

    try {
      const response = await axios.get(url);
      localStorage.setItem(cacheKey, JSON.stringify(response.data));
      localStorage.setItem(`${cacheKey}_timestamp`, Date.now());
      return response.data;
    } catch (error) {
      if (retries > 0) {
        const retryAfter = error.response?.headers["retry-after"];
        const waitTime = retryAfter ? parseInt(retryAfter) * 1000 : delay;
        await new Promise((resolve) => setTimeout(resolve, waitTime));
        return fetchWithCacheAndRetry(url, cacheKey, retries - 1, delay * 2);
      } else {
        throw error;
      }
    }
  };

  const fetchCategories = useCallback(async () => {
    setLoadingCategories(true);
    try {
      const categoriesData = await fetchWithCacheAndRetry(
        "https://admin.aristoc.co.ug:9443/api/v1/categories",
        "categories"
      );
      setCategories(categoriesData);

      if (categoryId) {
        const selectedCategory = categoriesData.find(
          (category) => category.id === categoryId
        );
        setCategoryName(
          selectedCategory ? selectedCategory.name : "Categories"
        );
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setLoadingCategories(false);
    }
  }, [categoryId]);

  const fetchProducts = useCallback(async (categoryId) => {
    setLoadingProducts(true);
    try {
      const response = await axios.get(
        `https://admin.aristoc.co.ug:9443/api/v1/categories/products/${categoryId}`
      );
      const productsData = response.data.flat();
      setProducts(Array.isArray(productsData) ? productsData : []);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoadingProducts(false);
    }
  }, []);

  const fetchSubcategories = useCallback(async (parentId) => {
    setLoadingSubcategories(true);
    setShowSubcategories(true);
    try {
      const subcategoriesData = await fetchWithCacheAndRetry(
        `https://admin.aristoc.co.ug:9443/api/v1/categories/childes/${parentId}`,
        `subcategories_${parentId}`
      );

      // Fetch products for each subcategory to get the first product image
      const subcategoriesWithProducts = await Promise.all(
        subcategoriesData.map(async (subcategory) => {
          const productsResponse = await axios.get(
            `https://admin.aristoc.co.ug:9443/api/v1/categories/products/${subcategory.id}`
          );
          const products = productsResponse.data.flat();
          // Add the first product's image to the subcategory
          return {
            ...subcategory,
            firstProductImage: products.length > 0 ? products[0].image : null,
          };
        })
      );

      setSubcategories(subcategoriesWithProducts);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    } finally {
      setLoadingSubcategories(false);
    }
  }, []);

  const handleCategoryChange = async (categoryId, isChecked, parentId) => {
    setLoadingProducts(true);

    if (isChecked) {
      try {
        setCheckedCategory(categoryId); // Update checked category
        const selectedCategory = categories.find(
          (category) => category.id === categoryId
        );
        if (selectedCategory) {
          setCategoryName(selectedCategory.name); // Update the header title
        }
        setShowSubcategories(false); // Hide subcategory grid
        // Only reset previous check and collapse other categories if the category is a main category (parent_id === 0)
        if (parentId === 0) {
          setExpandedCategories({ [categoryId]: true });
          setCheckedCategory(categoryId);
        }

        const response = await axios.get(
          `https://admin.aristoc.co.ug:9443/api/v1/categories/products/${categoryId}`
        );
        const productsData = response.data.flat();
        setProducts(Array.isArray(productsData) ? productsData : []);
      } catch (error) {
        console.error("Error fetching products for category:", error);
      }
    } else {
      // Deselecting a category, no reset if it's a subcategory (parent_id !== 0)
      setCheckedCategory(null);
      setExpandedCategories((prev) => ({ ...prev, [categoryId]: false }));
      setShowSubcategories(true); // Show subcategory grid
    }

    setLoadingProducts(false);
  };

  const renderCategories = (parentId = 0) => {
    return categories
      .filter((category) => category.parent_id === parentId)
      .sort((a, b) => a.name.localeCompare(b.name)) // Sort categories alphabetically by name
      .map((category) => (
        <div key={category.id}>
          <div
            className="category-item"
            onClick={() => toggleCategory(category.id, parentId)}
            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
          >
            <input
              type="checkbox"
              id={`category-${category.id}`}
              style={{ marginRight: "5px" }}
              checked={checkedCategory === category.id}
              onChange={
                (e) =>
                  handleCategoryChange(
                    category.id,
                    e.target.checked,
                    category.parent_id
                  ) // Pass parent_id
              }
            />
            <label htmlFor={`category-${category.id}`}>{category.name}</label>
            {categories.some(
              (subCategory) => subCategory.parent_id === category.id
            ) && (
              <span style={{ marginLeft: "auto" }}>
                {expandedCategories[category.id] ? "-" : "+"}
              </span>
            )}
          </div>
          {expandedCategories[category.id] && (
            <div style={{ marginLeft: "20px" }}>
              {renderCategories(category.id)} {/* Render subcategories */}
            </div>
          )}
        </div>
      ));
  };

  const renderSubcategories = () => {
    if (!showSubcategories) {
      return null; // Hide subcategory grid
    }

    else if (loadingSubcategories) {
      return (
        <div>
          <SkeletonLoader />
        </div>
      );
    }
    

    return (
      <div className="subcategory-grid">
        {subcategories
        .filter((subcategory) => subcategory.firstProductImage) // Filter subcategories with an image
        .slice(0, 12)
        .map((subcategory) => (
          <div
            key={subcategory.id}
            className="subcategory-item-container"
            onClick={() => fetchProducts(subcategory.id)} // Click to fetch products
          >
            <div className="category-item-rounded">
              <div className="rounded-item-image">
                {/* Use ProductImage to display the first product's image */}
                <ProductImage
                  imageArray={subcategory.firstProductImage} // Passing first product image
                  altText={subcategory.name}
                />
              </div>
            </div>
            <p className="rounded-item-title">{subcategory.name}</p>
          </div>
        ))}
      </div>
    );
  };

  const ProductImage = ({ imageArray, altText }) => {
    const [imageSrc, setImageSrc] = useState(
      imageArray && imageArray.length > 0
        ? `${BASE_IMAGE_URL_PUBLIC}${imageArray[0]}`
        : ""
    );

    const handleError = () => {
      setImageSrc(
        imageArray && imageArray.length > 0
          ? `${BASE_IMAGE_URL_STORAGE}${imageArray[0]}`
          : ""
      );
    };

    if (!imageArray || imageArray.length === 0) {
      return <div className="no-image">Sub-cat</div>;
    }

    return (
      <img
        src={imageSrc}
        alt={altText}
        onError={handleError}
        className="product-image"
      />
    );
  };

  const SkeletonLoader = () => (
    <div className="skeleton-loader">
      <div className="skeleton-image"></div>
      <div className="skeleton-text"></div>
      <div className="skeleton-text"></div>
      <div className="skeleton-button"></div>
    </div>
  );

  const handleLoadMore = () => {
    setVisibleProducts((prevVisibleProducts) => prevVisibleProducts + 18);
  };

  const handleProductClick = (productId) => {
    navigate(`/product-details/${productId}`);
  };

  const addToCart = (productId) => {
    const selectedProduct = products.find((p) => p.id === productId);

    if (!selectedProduct) {
      console.error("Product not found");
      return;
    }

    const cartItem = {
      id: selectedProduct.id,
      name: selectedProduct.name,
      quantity: 1,
      price: selectedProduct.price,
      image: selectedProduct.image,
    };

    let cart = JSON.parse(localStorage.getItem("cart")) || [];
    const existingItemIndex = cart.findIndex((item) => item.id === cartItem.id);

    if (existingItemIndex !== -1) {
      cart[existingItemIndex].quantity += cartItem.quantity;
    } else {
      cart.push(cartItem);
    }

    localStorage.setItem("cart", JSON.stringify(cart));
    window.dispatchEvent(new Event("cartUpdated"));
  };

  useEffect(() => {
    fetchCategories();
    if (categoryId) {
      fetchSubcategories(categoryId);
      fetchProducts(categoryId);
    }
  }, [fetchCategories, fetchSubcategories, fetchProducts, categoryId]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleCategory = (categoryId) => {
    setExpandedCategories((prevExpandedCategories) => ({
      ...prevExpandedCategories,
      [categoryId]: !prevExpandedCategories[categoryId],
    }));
  };

  return (
    <div>
      <TopBar />
      <div className="shop-container">
        <div className="categories-sidebar">
          <p className="category-header">Product Categories</p>
          {loadingCategories ? (
            <div className="skeleton-categories">
              {Array.from({ length: 5 }).map((_, index) => (
                <SkeletonLoader key={index} />
              ))}
            </div>
          ) : (
            renderCategories()
          )}
        </div>
        <div className="category-list">
          <h1 className="categories-title">{categoryName || "Categories"}</h1>
          {renderSubcategories()}

          {/* Grid View for Products */}
          <div className="products-grid">
            {products.length > 0 ? (
              products.slice(0, visibleProducts).map((product) => (
                <div className="product-item" key={product.id}>
                  <div
                    role="button"
                    className="product-item-content"
                    title={product.name}
                  >
                    <div onClick={() => handleProductClick(product.id)}>
                      <ProductImage
                        imageArray={product.image}
                        altText={product.name}
                      />
                    </div>
                    <h4 className="product-title">{product.name}</h4>
                    <p
                      className="product-description"
                      dangerouslySetInnerHTML={{ __html: product.description }}
                    ></p>
                    {renderStars(4.5)}
                    <p className="product-price">
                      UGX {product.price.toLocaleString()}
                    </p>
                    <button
                      className="add-to-cart-button"
                      onClick={() => addToCart(product.id)}
                    >
                      Add To Cart
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <div>Loading...</div>
            )}
          </div>

          {visibleProducts < products.length && (
            <div className="load-more-container">
              <button className="load-more-button" onClick={handleLoadMore}>
                Load More
              </button>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}
