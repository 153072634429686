import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const SearchInput = ({ setProducts }) => {
  const [query, setQuery] = useState('');
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();
  const [typingTimeout, setTypingTimeout] = useState(null);

  // Handle input change and trigger delayed API call
  const handleInputChange = (event) => {
    const input = event.target.value;
    setQuery(input);
    setDropdownVisible(true);

    // Clear previous timeout if user continues typing
    if (typingTimeout) clearTimeout(typingTimeout);

    // Set a new timeout to delay the API call and navigation
    setTypingTimeout(
      setTimeout(() => {
        if (input.trim()) {
          searchProducts(input);
        } else {
          setSuggestions([]);
        }
      }, 500)
    );
  };

  // Perform API call to fetch search results
  const searchProducts = async (input) => {
    try {
      const response = await axios.get('https://admin.aristoc.co.ug:9443/api/v1/products/search', {
        params: { name: input },
      });

      console.log("Searching for:", input);
      const products = response.data.products;
      setSuggestions(products);

      // Navigate to shop with search results
      navigate("/shop", { state: { searchResults: products } });
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  // Handle pressing Enter key
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (query.trim()) {
        searchProducts(query);
        setDropdownVisible(false);
        setProducts(suggestions);
      }
    }
  };

  // Handle suggestion click
  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion.name);
    setDropdownVisible(false);
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Search..."
        style={{
          width: '100%',
          padding: '8px',
          border: '1px solid #ccc',
          borderRadius: '4px',
        }}
      />
      {dropdownVisible && query && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            right: 0,
            border: '1px solid #ccc',
            borderTop: 'none',
            backgroundColor: 'white',
            maxHeight: '200px',
            overflowY: 'auto',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
            zIndex: 1000,
          }}
        >
          {suggestions.length > 0 ? (
            suggestions.map((suggestion) => (
              <div
                key={suggestion.id}
                onClick={() => handleSuggestionClick(suggestion)}
                style={{
                  padding: '8px',
                  cursor: 'pointer',
                  backgroundColor: '#fff',
                  borderBottom: '1px solid #ddd',
                }}
              >
                <div>{suggestion.name}</div>
              </div>
            ))
          ) : (
            <div
              style={{
                padding: '8px',
                cursor: 'pointer',
                backgroundColor: '#fff',
                borderBottom: '1px solid #ddd',
              }}
            >
              No suggestions found.
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchInput;
