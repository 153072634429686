import React, { useState, useEffect, useRef } from 'react';
import './CategorySlider.css';

const Banner = ({ image, title, description, buttonLabel }) => (
  <div className="banner-ad-component">
    <div className="banner-content">
      {/* Left Column: Title, Description, Button */}
      <div className="banner-text">
        <h2>{title}</h2>
        <p>{description}</p>
        <button className="banner-button">{buttonLabel}</button>
      </div>
      {/* Right Column: Image */}
      <div className="banner-image">
        <img src={image} alt={title} />
      </div>
    </div>
  </div>
);

const CategorySlider = () => {
  const [banners, setBanners] = useState([]);
  const sliderRef = useRef(null);

  useEffect(() => {
    // Fetch banners from the API
    const fetchBanners = async () => {
      try {
        const response = await fetch('https://admin.aristoc.co.ug:9443/api/v1/banners');
        const data = await response.json();

        // Transform data to include the full image URL
        const transformedBanners = data.map((banner) => ({
          ...banner,
          imageUrl: `https://admin.aristoc.co.ug:9443/storage/banner/${banner.image}`,
        }));

        setBanners(transformedBanners);
      } catch (error) {
        console.error('Error fetching banners:', error);
      }
    };

    fetchBanners();
  }, []);

  const scrollLeft = () => {
    if (sliderRef.current) {
      const widthToScroll = sliderRef.current.clientWidth; // Scroll by container width or single item width
      sliderRef.current.scrollBy({ left: -widthToScroll, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      const widthToScroll = sliderRef.current.clientWidth; // Scroll by container width or single item width
      sliderRef.current.scrollBy({ left: widthToScroll, behavior: 'smooth' });
    }
  };

  return (
    <div className="category-slider-container">
      <button className="scroll-button left" onClick={scrollLeft}>
        &#8249;
      </button>
      <div className="category-slider" ref={sliderRef}>
        {banners.map((banner) => (
          <Banner
            key={banner.id}
            image={banner.imageUrl}
            title={banner.title}
            description="Click for more details.!" 
            buttonLabel="View More" 
          />
        ))}
      </div>
      <button className="scroll-button right" onClick={scrollRight}>
        &#8250;
      </button>
    </div>
  );
};

export default CategorySlider;
