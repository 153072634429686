import React, { useState } from "react";
import "./SpecialOrder.css";
import TopBar from "../../components/TopBar";
import Footer from "../../components/Footer";

export default function SpecialOrder() {
  const [customer_name, setFullName] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [itemName, setItemName] = useState("");
  const [order_details, setItemDescription] = useState("");
  const [itemQuantity, setItemQuantity] = useState("");
  const [customizations, setCustomizations] = useState("");
  const [contactMethod, setContactMethod] = useState("email");
  const [contactInfo, setContactInfo] = useState("");
  const [notification, setNotification] = useState("");
  
  const authToken = localStorage.getItem("authToken");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!customer_name || !phonenumber || !itemName || !order_details || !itemQuantity || !contactInfo) {
      setNotification("Please fill in all required fields.");
      return;
    }

    const payload = {
      customer_name,
      phone_number: phonenumber,
      preferred_contact_method: contactMethod === "email" ? "Email" : "WhatsApp",
      email: contactMethod === "email" ? contactInfo : null,
      item_name: itemName,
      order_details,
      quantity: parseInt(itemQuantity, 10),
      customizations,
      status: 1,
    };

    try {
      const response = await fetch("https://admin.aristoc.co.ug:9443/api/v1/special-order/store", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (response.ok) {
        setNotification("Your special order request has been submitted successfully!");
        // Clear form fields after successful submission
        setFullName("");
        setPhonenumber("");
        setItemName("");
        setItemDescription("");
        setItemQuantity("");
        setCustomizations("");
        setContactInfo("");
      } else {
        setNotification(data.message || "Something went wrong. Please try again.");
      }
    } catch (error) {
      setNotification("Error submitting the order. Please try again.");
    }
  };

  return (
    <div>
      <TopBar />
      <div className="special-order-description">
        <p>
          Special Orders allow you to request items that are not currently
          available on our website. If you have a specific product in mind that
          you would like us to source or custom order for you, simply fill out
          the form with details such as the item name, description, quantity,
          and any special customizations you require. You can also specify your
          preferred contact method—whether it's via email or WhatsApp—so we can
          get in touch with you regarding your request. Once submitted, our team
          will review your special order and reach out to you with further
          details. Aristoc is here to help fulfill your unique product needs!
        </p>
      </div>
      <div className="special-order-page">
        <h1>Special Order Request Form</h1>

        {notification && (
          <div className="special-order-notification">{notification}</div>
        )}

        <div className="special-order-container">
          <form onSubmit={handleSubmit}>
            <div className="special-order-form-group">
              <label htmlFor="fullname">Full Name/Organization Name: *</label>
              <input
                type="text"
                id="fullname"
                value={customer_name}
                onChange={(e) => setFullName(e.target.value)}
                placeholder="Enter your full name"
                required
              />
            </div>
            <div className="special-order-form-group">
              <label htmlFor="phonenumber">Phone Number: *</label>
              <input
                type="text"
                id="phonenumber"
                value={phonenumber}
                onChange={(e) => setPhonenumber(e.target.value)}
                placeholder="Enter your phone number"
                required
              />
            </div>
            <div className="special-order-form-group">
              <label htmlFor="special-order-contact-method">
                Preferred Contact Method: *
              </label>
              <select
                id="special-order-contact-method"
                value={contactMethod}
                onChange={(e) => setContactMethod(e.target.value)}
                required
              >
                <option value="email">Email</option>
                <option value="whatsapp">WhatsApp</option>
              </select>
            </div>
            <div className="special-order-form-group">
              <label htmlFor="special-order-contact-info">
                Enter {contactMethod === "email" ? "Email" : "Phone Number"}: *
              </label>
              <input
                type={contactMethod === "email" ? "email" : "tel"}
                id="special-order-contact-info"
                value={contactInfo}
                onChange={(e) => setContactInfo(e.target.value)}
                placeholder={
                  contactMethod === "email"
                    ? "Enter your email address"
                    : "Enter your WhatsApp contact"
                }
                required
              />
            </div>
            <div className="special-order-form-group">
              <label htmlFor="special-order-item-name">Item Name: *</label>
              <input
                type="text"
                id="special-order-item-name"
                value={itemName}
                onChange={(e) => setItemName(e.target.value)}
                placeholder="Enter the name of the item"
                required
              />
            </div>

            <div className="special-order-form-group">
              <label htmlFor="special-order-item-description">
                Item Description: *
              </label>
              <textarea
                id="special-order-item-description"
                value={order_details}
                onChange={(e) => setItemDescription(e.target.value)}
                placeholder="Describe the item in detail"
                required
              />
            </div>

            <div className="special-order-form-group">
              <label htmlFor="special-order-item-quantity">Quantity: *</label>
              <input
                type="number"
                id="special-order-item-quantity"
                value={itemQuantity}
                onChange={(e) => setItemQuantity(e.target.value)}
                placeholder="Enter the quantity you need"
                required
                min="1"
              />
            </div>

            <div className="special-order-form-group">
              <label htmlFor="special-order-customizations">
                Customizations (optional):
              </label>
              <textarea
                id="special-order-customizations"
                value={customizations}
                onChange={(e) => setCustomizations(e.target.value)}
                placeholder="Any customizations or special requests?"
              />
            </div>

            <button type="submit" className="special-order-submit-button">
              Submit Special Order
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}
