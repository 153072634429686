// App.js
import "./App.css";
import React, { useState } from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/MyAccountScreen/my-account";
import HomePage from "./pages/HomeScreen/home";
import ProductDetails from "./pages/ProductDetails/ProductDetails";
import Checkout from "./pages/CheckoutScreen/Checkout";
import Shop from "./pages/ShopScreen/Shop";
import Categories from "./pages/CategoriesScreen/Categories";
import MyAccount from "./pages/MyAccountScreen/my-account";
import ContactUs from "./pages/ContactUsScreen/ContactUs";
import TermsConditions from "./pages/TermsConditions/TermsConditions";
import AboutUs from "./pages/AboutUs/AboutUs";
import RefundPolicy from "./pages/RefundPolicy/RefundPolicy";

import EGiftCard from "./pages/Gift/Gift";
import ForgotPassword from "./pages/forgotPassword/ForgotPassword";
import SpecialOrder from "./pages/specialOrder/SpecialOrder";
import SearchInput from "./components/SearchInput";
import ChatWidget from "./widgets/ChatWidget";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import PreCheckPage from "./pages/PreCheckScreen/PreCheckScreen";

function App() {
  const [cartItems, setCartItems] = useState([]);
  const [badgeCount, setBadgeCount] = useState(0);

  const clearCart = () => {
    setCartItems([]);
    setBadgeCount(0);
    localStorage.removeItem("cart");
    const event = new Event("cartUpdated");
    window.dispatchEvent(event);
  };
  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <Shop
              cartItems={cartItems}
              badgeCount={badgeCount}
              clearCart={clearCart}
            />
          }
        />
        <Route path="/product-details/:id" element={<ProductDetails />} />
        <Route path="/checkout" element={<Checkout clearCart={clearCart} />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/my-account" element={<MyAccount />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/gift-card" element={<EGiftCard />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/special-order" element={<SpecialOrder />} />
        <Route path="/search" element={<SearchInput />} />
        <Route path="/pre-check" element={<PreCheckPage />} />
      </Routes>
      <TawkMessengerReact
        propertyId="672ce2242480f5b4f59a5f12"
        widgetId="1ic3l6oah"
      />
    </div>
  );
}

export default App;
