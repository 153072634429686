import React from "react";
import "./AboutUs.css";
import TopBar from "../../components/TopBar";
import Footer from "../../components/Footer";

const AboutUs = () => {
  return (
    <div className="main-about-div">
      <TopBar />

      <div className="about-us">
        <h1>About Us</h1>

        <section className="about-section">
          <h2>Who We Are</h2>
          <p>
            Aristoc is a leading e-commerce platform based in Uganda. We are
            dedicated to offering an exceptional shopping experience for our
            customers, providing a wide range of high-quality products at the
            most affordable prices. Our focus is on delivering superior customer
            service and ensuring that all our customers have access to the best
            shopping experience online.
          </p>
          <p>
            At Aristoc, we believe that e-commerce should be accessible to
            everyone, and our platform aims to make shopping as convenient,
            affordable, and enjoyable as possible for people in Uganda and
            beyond.
          </p>
        </section>

        <section className="mission-section">
          <h2>Our Mission</h2>
          <p>
            Our mission is simple: to provide a platform where Ugandans and
            customers across Africa can access a wide variety of products,
            ranging from books, electronics, fashion, and household items, all
            in one place. We strive to make e-commerce a seamless and secure
            experience by delivering products quickly, reliably, and with
            outstanding customer support.
          </p>
        </section>

        <section className="our-values-section">
          <h2>Our Values</h2>
          <ul>
            <li>
              <strong>Customer Centricity:</strong> We put our customers first
              and strive to exceed their expectations every time.
            </li>
            <li>
              <strong>Integrity:</strong> We conduct business with honesty,
              transparency, and respect for our customers.
            </li>
            <li>
              <strong>Innovation:</strong> We are committed to providing
              innovative solutions that improve the shopping experience for our
              customers.
            </li>
            <li>
              <strong>Community Impact:</strong> We are passionate about
              contributing to the development of Uganda's local communities and
              economy.
            </li>
          </ul>
        </section>

        <section className="why-us-section">
          <h2>Why Choose Us?</h2>
          <p>
            Aristoc stands out because we care about your experience. Here’s why
            you should choose us:
          </p>
          <ul>
            <li>
              <strong>Wide Product Range:</strong> We offer a broad selection of
              products from books to electronics and fashion items.
            </li>
            <li>
              <strong>Reliable Delivery:</strong> We deliver products safely and
              on time within Uganda and internationally.
            </li>
            <li>
              <strong>Customer Support:</strong> Our dedicated customer support
              team is here to help you with any issues you may face.
            </li>
            <li>
              <strong>Secure Payments:</strong> We offer secure online payment
              options, ensuring that your transactions are safe and protected.
            </li>
          </ul>
        </section>

        <section className="contact-section">
          <h2>Contact Us</h2>
          <p>
            Have any questions or concerns? We’d love to hear from you! You can
            contact us via:
          </p>
          <ul>
            <li>
              Email:{" "}
              <a href="mailto:support@aristoc.co.ug">support@aristoc.co.ug</a>
            </li>
            <li>Phone: +256 708 695136</li>
          </ul>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
