import React, { Component } from 'react'
import './Promo.css'

export default class Promo extends Component {
  render() {
    return (
        <div id="promotionBar" className="promotion-bar mobile-hidden">
		<div className="inner">
			<span id="promotionClose" className="panel-close"></span>
			30% discount on all products special for June!
		</div>
	</div>
    )
  }
}
