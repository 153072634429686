import React from "react";
import "./TermsConditions.css";
import Footer from "../../components/Footer";
import TopBar from "../../components/TopBar";

const TermsConditions = () => {
  return (
    <div>
      <TopBar />
      <div className="terms-conditions">
        <h1>Terms and Conditions</h1>

        <section>
          <h2>1. Introduction</h2>
          <p>
            Welcome to Aristoc! These terms and conditions outline the rules and
            regulations for the use of our website and services.
          </p>
          <p>
            By accessing or using the website, you agree to comply with these
            Terms and Conditions. If you disagree with any part of the terms,
            you must not use our services.
          </p>
        </section>

        <section>
          <h2>2. Use of Our Services</h2>
          <p>
            Our website is designed for individuals who are 18 years or older.
            If you are under the age of 18, you may only use the website under
            the supervision of a parent or legal guardian.
          </p>
          <p>
            You agree to use our services in accordance with all applicable
            local, state, and international laws. You must not use the services
            for any unlawful purposes.
          </p>
        </section>

        <section>
          <h2>3. Products and Pricing</h2>
          <p>
            We make every effort to ensure that all product descriptions,
            pricing, and images on the website are accurate. However, we do not
            guarantee that the information is always up-to-date or free of
            errors. Prices and availability are subject to change without
            notice.
          </p>
          <p>
            In the event of a pricing error, we reserve the right to cancel or
            modify the order.
          </p>
        </section>

        <section>
          <h2>4. Orders and Payments</h2>
          <p>
            When placing an order on our website, you agree to provide accurate
            and complete information. We reserve the right to cancel or refuse
            any order at our discretion.
          </p>
          <p>
            All payments for orders are processed securely via our third-party
            payment gateway. You are responsible for ensuring that your payment
            details are accurate and up-to-date.
          </p>
        </section>

        <section>
          <h2>5. Shipping and Delivery</h2>
          <p>
            We strive to ensure timely delivery of products to our customers.
            Once an order is placed, you will be notified of the estimated
            delivery date. However, we do not guarantee delivery within a
            specific time frame.
          </p>
          <p>
            Any delays in shipping are the responsibility of the third-party
            courier services we use.
          </p>
        </section>

        <section>
          <h2>6. Returns and Refunds</h2>
          <p>
            We accept returns and provide refunds in accordance with our return
            policy. Items must be returned in their original condition within
            [X] days of receiving the order to be eligible for a refund or
            exchange.
          </p>
          <p>
            Please visit our Return & Refund Policy page for more detailed
            information on how to initiate a return.
          </p>
        </section>

        <section>
          <h2>7. Privacy Policy</h2>
          <p>
            We value your privacy. By using our website, you consent to the
            collection and use of your personal information as outlined in our
            Privacy Policy.
          </p>
          <p>For more details, please read our Privacy Policy page.</p>
        </section>

        <section>
          <h2>8. Limitation of Liability</h2>
          <p>
            Aristoc is not liable for any damages or losses arising from the use
            or inability to use our services. This includes any direct,
            indirect, incidental, or consequential damages, even if we have been
            advised of the possibility of such damages.
          </p>
        </section>

        <section>
          <h2>9. Changes to the Terms</h2>
          <p>
            We reserve the right to modify or replace these Terms and Conditions
            at any time. Any changes will be posted on this page with the
            updated date. By continuing to use our services after any changes,
            you agree to the updated terms.
          </p>
        </section>

        <section>
          <h2>10. Contact Us</h2>
          <p>
            If you have any questions about these Terms and Conditions, please
            contact us at:
          </p>
          <p>
            Email:{" "}
            <a href="mailto:support@aristoc.co.ug">support@aristoc.co.ug</a>
          </p>
          <p>Phone: +256 708 695136</p>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default TermsConditions;
