// ProductFilters.js
import React from 'react';
import './ProductFilters.css'; // Ensure you create this CSS file for styling

const filters = [
  { name: 'All Products', icon: 'fa-list' },
  { name: 'Featured', icon: 'fa-star' },
  { name: 'Best Sellers', icon: 'fa-thumbs-up' },
  { name: 'Top Rated', icon: 'fa-star-half-alt' },
  { name: 'On Sale', icon: 'fa-tags' },
  { name: 'In Stock', icon: 'fa-check' },
];

const ProductFilters = () => {
  return (
    <div className="filters-container">
      <h2 className="filters-title">Product Filters</h2>
      <div className="filters-badges">
        {filters.map((filter, index) => (
          <div key={index} className="filter-badge">
            <i className={`fa ${filter.icon}`}></i>
            <span className="filter-name">{filter.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductFilters;
