import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./HeaderMiddle.css";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import NotificationsNoneTwoToneIcon from "@mui/icons-material/NotificationsNoneTwoTone";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import Badge from "@mui/material/Badge";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { styled } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { IconButton, Tooltip } from "@mui/material";
import logo from "../../../assets/img/aristoc-logo-standart.png";
import ProductImage from "../../../components/ProductImage";

// Snackbar Alert component
const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#EE1926",
    color: "#fff",
  },
}));

const HeaderMiddle = ({ clearCart }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [badgeCount, setBadgeCount] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const navigate = useNavigate();

  // Debounce function to limit the number of API calls
  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const contactNumber = "+256708695136";

  const fetchSuggestions = async (query) => {
    if (query.trim()) {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Cancelling the previous request.");
      }

      const source = axios.CancelToken.source();
      setCancelTokenSource(source);

      try {
        const response = await axios.get(
          `https://admin.aristoc.co.ug:9443/api/v1/products/search`,
          {
            params: { name: query },
            cancelToken: source.token,
          }
        );

        const products = response.data.products;
        const sortedSuggestions = products.sort((a, b) => {
          const aStartsWithQuery = a.name
            .toLowerCase()
            .startsWith(query.toLowerCase());
          const bStartsWithQuery = b.name
            .toLowerCase()
            .startsWith(query.toLowerCase());

          if (aStartsWithQuery && !bStartsWithQuery) return -1;
          if (!aStartsWithQuery && bStartsWithQuery) return 1;
          return 0;
        });

        setSuggestions(sortedSuggestions);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Previous request cancelled:", error.message);
        } else {
          console.error("Error fetching suggestions:", error);
        }
      }
    } else {
      setSuggestions([]);
    }
  };

  // Debounce the fetchSuggestions call
  const debouncedFetchSuggestions = debounce(fetchSuggestions, 100); // Set delay to 100ms

  const handleInputChange = (event, newValue) => {
    setSearchQuery(newValue);
    debouncedFetchSuggestions(newValue); // Call the debounced function
  };

  const handleSuggestionSelect = (event, value) => {
    if (value) {
      setSearchQuery(value.name);
      navigate("/shop", { state: { searchResults: [value] } });
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const updateCart = () => {
      const cart = JSON.parse(localStorage.getItem("cart")) || [];
      setCartItems(cart);
      setBadgeCount(cart.reduce((acc, item) => acc + item.quantity, 0));
    };

    updateCart();
    window.addEventListener("cartUpdated", updateCart);

    return () => {
      window.removeEventListener("cartUpdated", updateCart);
    };
  }, []);

  const handleIncreaseQuantity = (id) => {
    const updatedCart = cartItems.map((item) =>
      item.id === id ? { ...item, quantity: item.quantity + 1 } : item
    );
    setCartItems(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    setBadgeCount(updatedCart.reduce((acc, item) => acc + item.quantity, 0));
    const event = new Event("cartUpdated");
    window.dispatchEvent(event);
  };

  const handleDecreaseQuantity = (id) => {
    const updatedCart = cartItems.map((item) =>
      item.id === id && item.quantity > 1
        ? { ...item, quantity: item.quantity - 1 }
        : item
    );
    setCartItems(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    setBadgeCount(updatedCart.reduce((acc, item) => acc + item.quantity, 0));
    const event = new Event("cartUpdated");
    window.dispatchEvent(event);
  };

  const handleDelete = (id) => {
    const updatedCart = cartItems.filter((item) => item.id !== id);

    setCartItems(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    setBadgeCount(updatedCart.reduce((acc, item) => acc + item.quantity, 0));
    const event = new Event("cartUpdated");
    window.dispatchEvent(event);
  };

  const totalPrice = cartItems.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  );

  const handleCheckout = () => {
    const token = localStorage.getItem("authToken");

    if (token) {
      navigate("/pre-check", { state: { cartItems, totalPrice, token } });
    } else {
      navigate("/my-account", { state: { fromCheckout: true } });
    }
  };

  const handleAccountClick = () => {
    navigate("/my-account");
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSearchSubmit = async () => {
    if (searchQuery.trim()) {
      try {
        const response = await axios.get(
          `https://admin.aristoc.co.ug:9443/api/v1/products/search`,
          {
            params: { name: searchQuery },
          }
        );

        const products = response.data.products;
        setSuggestions(products); // Update suggestions with full product list

        // Navigate to shop with full list of search results
        navigate("/shop", { state: { searchResults: products } });
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    }
  };

  // Add handleKeyDown to submit search on Enter key
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearchSubmit();
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="header-middle">
      <div className="mainlogo">
        <Link to="/">
          <img src={logo} alt="Logo" />
        </Link>
      </div>
      <div className="search-container">
        <Autocomplete
          className="search-input"
          freeSolo
          options={suggestions}
          // getOptionLabel={(option) => option.name}
          getOptionLabel={(option) => option.name || " "} // Fallback if name is undefined
          onInputChange={handleInputChange}
          onChange={handleSuggestionSelect}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search for products"
              variant="outlined"
              onKeyDown={handleKeyDown} // Detect Enter key
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    <IconButton onClick={handleSearchSubmit}>
                      <SearchOutlinedIcon />
                    </IconButton>
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </div>
      <div className="contact-info">
        <a
          href={`https://wa.me/${contactNumber}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          <WhatsAppIcon style={{ color: "green", cursor: "pointer" }} />
        </a>
        <div className="contact-text">
          <span className="contact-call">Call Now/Whatsapp</span>
          <span className="contact-number">+256708695136</span>
        </div>
      </div>
      <div className="icons">
      <Tooltip title="Shopping Cart" arrow>
        <div>
        <CustomBadge
          badgeContent={badgeCount}
          color="secondary"
          onClick={handleClick}
        >
          <ShoppingCartOutlinedIcon style={{ cursor: "pointer" }} />
        </CustomBadge>
        </div>
        </Tooltip>
        <Tooltip title="Compare" arrow>
        <div>
        <CustomBadge badgeContent={0} color="secondary">
          <AutorenewOutlinedIcon />
        </CustomBadge>
        </div>
        </Tooltip>
        <Tooltip title="Wishlist" arrow>
        <div>
        <CustomBadge badgeContent={0} color="secondary">
          <FavoriteBorderOutlinedIcon />
        </CustomBadge>
        </div>
        </Tooltip>
        <Tooltip title="My Account" arrow>
        <div>
        <div onClick={handleAccountClick} style={{ cursor: "pointer" }}>
          <AccountCircleOutlinedIcon />
        </div>
        </div>
        </Tooltip>
        <Tooltip title="Notifications" arrow>
        <div>
        <CustomBadge badgeContent={0} color="secondary">
          <NotificationsNoneTwoToneIcon />
        </CustomBadge>
        </div>
        </Tooltip>
      </div>

      {/* Popover for cart items */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: { maxWidth: "400px" }, // Set max width for the popover
        }}
      >
        <Typography sx={{ p: 2, fontSize: "0.8rem" }} component="div">
          <h3 style={{ fontSize: "1rem", marginBottom: "6px" }}>Cart Items</h3>
          {cartItems.length === 0 ? (
            <p style={{ fontSize: "0.75rem", marginBottom: "8px" }}>
              Your cart is empty.
            </p>
          ) : (
            cartItems.map((item) => (
              <div
                key={item.id}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "8px",
                  borderBottom: "1px solid #ddd",
                  paddingBottom: "8px",
                }}
              >
                <div
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "5px",
                    marginRight: "10px",
                  }}
                >
                  {" "}
                  <ProductImage product={item} />
                </div>

                <div style={{ flex: 1, fontSize: "0.75rem" }}>
                  <h4
                    style={{
                      fontSize: "0.85rem",
                      margin: "4px 0",
                      color: "black",
                    }}
                  >
                    Name: {item.name}
                  </h4>
                  <p style={{ margin: "2px 0" }}>Price: {item.price}</p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <button
                        onClick={() => handleDecreaseQuantity(item.id)}
                        style={{
                          padding: "0 5px",
                          fontSize: "0.75rem",
                          backgroundColor: "#f0f0f0",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                        }}
                      >
                        -
                      </button>
                      <span style={{ margin: "0 5px" }}>
                        Qty: {item.quantity}
                      </span>
                      <button
                        onClick={() => handleIncreaseQuantity(item.id)}
                        style={{
                          padding: "0 5px",
                          fontSize: "0.75rem",
                          backgroundColor: "#f0f0f0",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                        }}
                      >
                        +
                      </button>
                    </div>
                    <IconButton
                      onClick={() => handleDelete(item.id)}
                      size="small"
                      style={{ fontSize: "0.75rem", marginLeft: "8px" }}
                    >
                      <DeleteIcon className="cart-delete-button" />
                    </IconButton>
                  </div>
                </div>
              </div>
            ))
          )}
        </Typography>
        <div style={{ padding: "8px 14px", textAlign: "center" }}>
          <h4 style={{ fontSize: "0.9rem", marginBottom: "6px", color: 'black' }}>
            Total Price: {totalPrice}
          </h4>
          <button
            onClick={handleCheckout}
            style={{
              padding: "5px 10px",
              fontSize: "0.8rem",
              borderRadius: "6px",
              backgroundColor: "#007bff",
              color: "#fff",
              border: "none",
              cursor: "pointer",
            }}
          >
            Proceed to Checkout
          </button>
        </div>
      </Popover>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default HeaderMiddle;
