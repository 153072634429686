import React, { useEffect, useState, Suspense, lazy, useTransition } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import TopBar from "../../components/TopBar";
import Skeleton from "@mui/material/Skeleton";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import "./ProductDetails.css";
import FullWidthTabs from "./FullWidthTabs";
import paymentImg from "../../assets/img/payment.webp";
import Footer from "../../components/Footer";
import books from "../../assets/img/books.png";
import WishlistPopover from "../../components/WishlistPopover";



// Lazy-loaded components
const ProductImage = lazy(() => import("../../components/ProductImage"));

const ProductDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [loadedThumbnails, setLoadedThumbnails] = useState({});
  const [mainImage, setMainImage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Pagination state
  const itemsPerPage = 5; // Number of items per page

  // Calculate pagination
  const totalPages = Math.ceil(similarProducts.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentProducts = similarProducts.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };



  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await fetch(
          `https://admin.aristoc.co.ug:9443/api/v1/products/details/${id}`
        );
        if (!response.ok) throw new Error("Network response was not ok");
        const data = await response.json();
        setProduct(data.product);
      } catch (error) {
        console.error("Error fetching product details:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await fetch(
          "https://admin.aristoc.co.ug:9443/api/v1/categories"
        );
        if (!response.ok) throw new Error("Network response was not ok");
        const data = await response.json();
        setCategories(data || []);
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setCategoriesLoading(false);
      }
    };

    fetchProductDetails();
    fetchCategories();
  }, [id]);

  useEffect(() => {
    if (product) {
      const fetchSimilarProducts = async () => {
        const categoryIds = product.category_ids.map((cat) => cat.id);
        try {
          const response = await fetch(
            `https://admin.aristoc.co.ug:9443/api/v1/categories/products/${categoryIds[1]}`
          );
          if (!response.ok) throw new Error("Network response was not ok");
          const data = await response.json();
          setSimilarProducts(data.filter((p) => p.id !== product.id)); // Exclude the current product
        } catch (error) {
          console.error("Error fetching similar products:", error);
        }
      };
      fetchSimilarProducts();
    }
  }, [product]);

  const addToCart = () => {
    if (product) {
      const cartItem = {
        id: product.id,
        name: product.name,
        quantity,
        price: product.price,
        image: product.image,
      };

      // Check if the quantity exceeds the available stock
      if (quantity > product.total_stock) {
        alert(`Only ${product.total_stock} units of ${product.name} are available in stock.`);
        return; // Exit the function if the quantity is invalid
      }

      let cart = JSON.parse(localStorage.getItem("cart")) || [];
      const existingItemIndex = cart.findIndex((item) => item.id === cartItem.id);

      if (existingItemIndex !== -1) {
        const newQuantity = cart[existingItemIndex].quantity + cartItem.quantity;
        if (newQuantity > product.total_stock) {
          alert(`Adding ${quantity} would exceed the available stock. Only ${product.total_stock - cart[existingItemIndex].quantity} more units can be added.`);
          return; // Exit the function if the total exceeds available stock
        }
        cart[existingItemIndex].quantity = newQuantity;
      } else {
        cart.push(cartItem);
      }

      localStorage.setItem("cart", JSON.stringify(cart));
      window.dispatchEvent(new Event("cartUpdated"));
    }
  };


  const handleQuantityChange = (delta) => {
    setQuantity((prevQuantity) => Math.max(prevQuantity + delta, 1));
  };

  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const truncateDescription = (text, wordLimit) => {
    const words = text.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : text;
  };

  const getCategoryName = (categoryId) => {
    const category = categories.find(
      (cat) => Number(cat.id) === Number(categoryId)
    );
    console.log("Category IDs:", product.category_ids);
    console.log("Categories:", categories);
    return category ? category.name : "";
  };
  console.log(categories);

  const handleThumbnailClick = (imageIndex) => {
    const baseURL = "https://admin.aristoc.co.ug:9443/storage/product/";
    const thumbnailName = product.image[imageIndex]; // Get the image name
    const thumbnailImage = `${baseURL}${thumbnailName}`; // Construct the full URL
    console.log("Selected Thumbnail Image URL:", thumbnailImage); // Debugging
    setMainImage(thumbnailImage); // Set the constructed URL as the main image
  };
  

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleResetMainImage = () => {
    setMainImage(null); // Reset to show the original ProductImage component
  };

     // Track when thumbnails are loaded
  const handleThumbnailLoad = (index) => {
    setLoadedThumbnails((prev) => ({ ...prev, [index]: true }));
  };
  

  return (
    <div>
      <TopBar />
      <div className="product-details-container">
        <div className="product-main-content">
          <div className="product-top-row">
          <div className="product-image-container">
      {/* Main Product Image */}
      {loading || !product ? (
        <Skeleton variant="rectangular" width={400} height={400} />
      ) : mainImage ? (
        // Show the clicked thumbnail image
        <img
          src={mainImage}
          alt="Selected Thumbnail"
          className="main-product-image"
          onClick={handleResetMainImage} // Click to reset (optional)
        />
      ) : (
        // Show the default ProductImage component
        <Suspense fallback={<Skeleton variant="rectangular" width={400} height={400} />}>
          <ProductImage
            product={product} // Pass the product object
            className="main-product-image"
          />
        </Suspense>
      )}

      {/* Thumbnails */}
      <div className="product-thumbnails">
        {product?.image?.map((image, index) => (
          <div key={index}>
            {!loadedThumbnails[index] && (
              <Skeleton variant="rectangular" width={80} height={80} />
            )}
            <ProductImage
              style={{
                width: "80px",
                height: "80px",
                display: loadedThumbnails[index] ? "block" : "none",
              }}
              product={product} // Pass the product
              imageIndex={index} // Pass the respective index for each thumbnail
              className="thumbnail-image"
              onLoad={() => handleThumbnailLoad(index)} // Mark as loaded when the image loads
              onError={() => handleThumbnailLoad(index)} // Handle errors gracefully
              onClick={() => handleThumbnailClick(index)} // Set clicked thumbnail as main
            />
          </div>
        ))}
      </div>
    </div>


            <div className="product-info-container">
              {loading ? (
                <Skeleton variant="text" width={300} height={40} />
              ) : product ? (
                <>
                  <h1 className="product-detail-title">{product.name}</h1>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center", // Vertically aligns items
                      gap: "8px", // Adjust spacing between the elements
                    }}
                  >
                    <Chip
                      label="Bestseller"
                      sx={{
                        backgroundColor: "#C45500",
                        color: "white",
                        borderRadius: 0,
                      }}
                    />
                    <p
                      className="category-name"
                      style={{
                        color: "#007185",
                        fontWeight: 500,
                        margin: 0, // Remove default paragraph margin
                      }}
                    >
                      {product.category_ids && product.category_ids.length > 0
                        ? `in ${getCategoryName(product.category_ids[1]?.id)}`
                        : "No category available"}
                    </p>
                  </div>

                  <Divider style={{ marginBottom: "20px", marginTop: "10px" }} />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: showFullDescription
                        ? product.description
                        : truncateDescription(product.description, 70),
                    }}
                  />
                  {product.description.split(" ").length > 70 && (
                    <button
                      className="read-more-link"
                      onClick={handleToggleDescription}
                    >
                      {showFullDescription ? "Read Less" : "Read More"}
                    </button>
                  )}
                  <p className="product-id">ISBN: {product.tax}</p>
                  <p className="product-details-price">
                    UGX {product.price.toLocaleString()}
                  </p>
                  <div className="buy-container">
                    <div className="quantity-controls">
                      <button
                        className="quantity-button"
                        onClick={() => handleQuantityChange(-1)}
                      >
                        <RemoveIcon />
                      </button>
                      <input
                        type="text"
                        className="quantity-input"
                        value={quantity}
                        readOnly
                      />
                      <button
                        className="quantity-button"
                        onClick={() => handleQuantityChange(1)}
                      >
                        <AddIcon />
                      </button>
                    </div>
                    <WishlistPopover productId={product.id} />
                    <button className="buy-button" onClick={addToCart}>
                      Add to Cart
                    </button>
                  </div>
                </>
              ) : (
                <div>Product not found.</div>
              )}
            </div>
          </div>

          <div className="tabs-container">
            <FullWidthTabs choiceOptions={product?.choice_options || []} />
          </div>
        </div>

        <div className="product-categories-container">
          <h2
            style={{
              fontFamily: "sans-serif",
              fontSize: "18px",
              fontWeight: "bold",
              marginBottom: "16px",
              textAlign: "center",
            }}
          >
            Similar Products
          </h2>
          {similarProducts.length === 0 ? (
            <Skeleton variant="rectangular" width="100%" height={100} />
          ) : (
            <div>
              <div className="similar-products">
                {currentProducts.map((similarProduct) => (
                  <a
                    key={similarProduct.id}
                    href={`/product-details/${similarProduct.id}`}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="similar-product-card">
                      <div className="similar-product-image">
                        {similarProduct && (
                          <ProductImage
                            product={similarProduct}
                            className="similar-product-image"
                            alt={similarProduct.name}
                          />
                        )}
                      </div>
                      <div className="similar-product-details">
                        <p className="similar-product-name">
                          {similarProduct.name.length > 45
                            ? `${similarProduct.name.slice(0, 45)}...`
                            : similarProduct.name}
                        </p>
                        <p className="similar-product-price">
                          UGX{similarProduct.price}
                        </p>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
              <div className="pagination-controls">
                <button
                  onClick={handlePrevious}
                  disabled={currentPage === 1}
                  style={{
                    marginTop: "10px",
                    marginRight: "8px",
                    padding: "6px 12px",
                    backgroundColor: currentPage === 1 ? "#f5a5a5" : "#ff0000", // Lighter red when disabled
                    color: "#fff",
                    border: "none",
                    borderRadius: "8px", // Rounded corners
                    cursor: currentPage === 1 ? "not-allowed" : "pointer",
                    fontSize: "14px",
                  }}
                >
                  Previous
                </button>
                <button
                  onClick={handleNext}
                  disabled={currentPage === totalPages}
                  style={{
                    padding: "6px 12px",
                    backgroundColor: currentPage === totalPages ? "#f5a5a5" : "#ff0000", // Lighter red when disabled
                    color: "#fff",
                    border: "none",
                    borderRadius: "8px", // Rounded corners
                    cursor: currentPage === totalPages ? "not-allowed" : "pointer",
                    fontSize: "14px",
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Image Modal */}
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          className: 'modal-backdrop',
        }}
      >
        <Fade in={modalOpen}>
          <div className="image-modal-content">
            {currentImageIndex !== null && (
              <Suspense fallback={<Skeleton variant="rectangular" width={400} height={400} />}>
                <ProductImage
                  product={product} // Show the clicked image
                  imageIndex={currentImageIndex}
                  alt={`Image ${currentImageIndex}`}
                  className="zoomed-image"
                />
              </Suspense>
            )}
          </div>
        </Fade>
      </Modal>


      <Footer />
    </div>
  );
};

export default ProductDetails;
