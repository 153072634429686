import React, { useState } from "react";
import "./ContactUs.css";
import Footer from "../../components/Footer";
import TopBar from "../../components/TopBar";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [formStatus, setFormStatus] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormStatus("Thank you for your message! We will get back to you soon.");
    setFormData({ name: "", email: "", phone: "", message: "" });
  };

  return (
    <div className="contact-us-page">
      <TopBar />
      <div className="contact-us-container">
        <h1 className="contact-us-title">Contact Us</h1>
        <p className="contact-us-description">
          Have a question or need assistance? Reach out to us, and we’ll get
          back to you as soon as possible.
        </p>

        <div className="contact-us-content">
          {/* Contact Form Column */}
          <div className="contact-form-container">
            <form className="contact-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  placeholder="Your Name"
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  placeholder="Your Email"
                />
              </div>

              <div className="form-group">
                <label htmlFor="phone">Phone Number</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  placeholder="Your Phone Number"
                />
              </div>

              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  placeholder="Your Message"
                />
              </div>

              <button type="submit" className="submit-button">
                Send Message
              </button>
            </form>

            {formStatus && <div className="form-status">{formStatus}</div>}
          </div>

          {/* Contact Details Column */}
          <div className="contact-details-container">
            <h2 className="contact-details-title">Our Contact Details</h2>
            <ul className="contact-details-list">
              <li>
                <strong>Phone:</strong> 0782-198-897
              </li>
              <li>
                <strong>Phone:</strong> 0751-141-780
              </li>
              <li>
                <strong>Whatsapp:</strong> 0708-695-136
              </li>
              <li>
                <strong>Email:</strong> abluga@infocom.co.ug
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
