import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import FavoriteIcon from "@mui/icons-material/Favorite";

export default function WishListPopover({ productId }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false); // Ensure this state is a boolean
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null); // To display errors

  // Handle popover open
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true); // Make sure open is set to `true` when the popover opens
  };

  // Handle popover close
  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpen(false); // Close the popover and set open to `false`
  };

  // Handle adding item to wishlist
  const handleAddToWishlist = async () => {
    const authToken = localStorage.getItem("authToken"); // Assuming the token is stored in localStorage
    if (!authToken) {
      setErrorMessage("You must be logged in to add items to the wishlist.");
      return;
    }

    const data = {
      productId: productId,
    };

    console.log(data);

    try {
      const response = await fetch("https://your-api-url.com/api/v1/wishlist", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Failed to add item to wishlist.");
      }

      setShowSuccessMessage(true);

      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);
    } catch (error) {
      setErrorMessage(error.message);
      console.log(error);
    }
  };

  return (
    <div>
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{ color: "red" }}
      >
        <FavoriteIcon
          sx={{ color: "red", cursor: "pointer" }}
          onClick={handleAddToWishlist} // Add click handler here
        />
      </Typography>

      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: "none" }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1, color: "red" }}>
          Add item to wishlist.
        </Typography>
      </Popover>

      {showSuccessMessage && (
        <Popover
          sx={{
            pointerEvents: "none",
            position: "absolute",
            top: "50px",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "green",
            color: "white",
            padding: "10px",
            borderRadius: "4px",
            zIndex: 1300,
          }}
        >
          <Typography sx={{ color: "white" }}>
            Item added to wishlist!
          </Typography>
        </Popover>
      )}

      {errorMessage && (
        <Popover
          sx={{
            pointerEvents: "none",
            position: "absolute",
            top: "50px",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "red",
            color: "white",
            padding: "10px",
            borderRadius: "4px",
            zIndex: 1300,
          }}
        >
          <Typography sx={{ color: "white" }}>{errorMessage}</Typography>
        </Popover>
      )}
    </div>
  );
}
