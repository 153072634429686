import React from "react";
import "./Footer.css";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa"; // For social media icons
import XIcon from "@mui/icons-material/X";
import googlePlayIcon from "../assets/img/google-play.png";
import appStoreIcon from "../assets/img/app-store.png";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import { useNavigate } from "react-router-dom"; // Import useNavigate

export default function Footer() {
  const navigate = useNavigate(); // Initialize navigate

  return (
    <div className="footer-container">
      <div className="footer-top">
        <div className="footer-left">
          <h3>Social Media</h3>
          <div className="social-media-icons">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/AristocUg"
            >
              <FaFacebook />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/aristoc_booklex"
            >
              <XIcon style={{ fontSize: "15px" }} />
            </a>

            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/aristoc_bookstore/"
            >
              <FaInstagram />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://ug.linkedin.com/company/aristoc-booklex"
            >
              <FaLinkedin />
            </a>
          </div>

          <h3>Download Apps:</h3>
          <div className="download-icons">
            <img src={googlePlayIcon} alt="Google Play" className="app-icon" />
            <img src={appStoreIcon} alt="App Store" className="app-icon" />
          </div>

          <h3>Subscribe to the newsletter</h3>
          <input
            type="email"
            placeholder="Enter your email"
            className="email-input"
          />

          <div className="help-section">
            <div className="help-left">
              <div className="icon-circle">
                <HeadsetMicIcon className="help-icon" />
              </div>
            </div>

            <div className="help-right">
              <h3>Need Help? Call Now!</h3>
              <p className="phone-number">0782-198-897</p>
              <p className="phone-number">0751-141-780</p>
              <p className="phone-number">0708-695-136 (Whatsapp only)</p>
            </div>
          </div>
        </div>

        <div className="footer-right">
          <div className="footer-section">
            <h4>About Aristoc</h4>
            <ul>
              <li>
                <a href="#" onClick={() => navigate("/aristoc-inside")}>
                  Aristoc Inside
                </a>{" "}
                {/* Added navigate */}
              </li>
              <li>
                <a onClick={() => navigate("/about-us")}>About Us</a>{" "}
                {/* Replaced href with navigate */}
              </li>
              <li>
                <a onClick={() => navigate("/contact-us")}>Contact Us</a>{" "}
                {/* Replaced href with navigate */}
              </li>
            </ul>
          </div>
          <div className="footer-section">
            <h4>Quick Links</h4>
            <ul>
              <li>
                <a href="#" onClick={() => navigate("/career")}>
                  Career
                </a>{" "}
                {/* Added navigate */}
              </li>
              <li>
                <a href="#" onClick={() => navigate("/brands")}>
                  Brands
                </a>{" "}
                {/* Added navigate */}
              </li>
              <li>
                <a href="#" onClick={() => navigate("/company")}>
                  Company
                </a>{" "}
                {/* Added navigate */}
              </li>
            </ul>
          </div>
          <div className="footer-section">
            <h4>Policies</h4>
            <ul>
              <li>
                <a onClick={() => navigate("/terms-conditions")}>
                  Terms & Conditions
                </a>{" "}
                {/* Replaced href with navigate */}
              </li>
              <li>
                <a onClick={() => navigate("/refund-policy")}>Refund Policy</a>{" "}
                {/* Replaced href with navigate */}
              </li>
              <li>
                <a href="#" onClick={() => navigate("/returns")}>
                  Returns
                </a>{" "}
                {/* Added navigate */}
              </li>
            </ul>
          </div>
          <div className="footer-section">
            <h4>Brands</h4>
            <ul>
              <li>
                <a href="#" onClick={() => navigate("/marvel")}>
                  Marvel
                </a>{" "}
                {/* Added navigate */}
              </li>
              <li>
                <a href="#" onClick={() => navigate("/dc")}>
                  DC
                </a>{" "}
                {/* Added navigate */}
              </li>
              <li>
                <a href="#" onClick={() => navigate("/disney")}>
                  Disney
                </a>{" "}
                {/* Added navigate */}
              </li>
            </ul>
          </div>

        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Aristoc Booklex Kampala</p>
      </div>
    </div>
  );
}
