import React, { useState, useEffect } from "react";
import "./Dashboard.css";

const Dashboard = () => {
  const [accountDetails, setAccountDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAccountDetails = async () => {
      const token = localStorage.getItem("authToken");

      if (!token) {
        setError("No authentication token found");
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(
          "https://admin.aristoc.co.ug:9443/api/v1/customer/info",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        setAccountDetails(data);
        console.log(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAccountDetails();
  }, []);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">Error: {error}</div>;
  }

  return (
    <div className="dashboard-container">
      <h1 className="dashboard-title">Your Details</h1>
      {accountDetails ? (
        <div className="account-details">
          <img src={accountDetails.image_fullpath} alt="profile" />
          <p>
            <strong>Name:</strong>{" "}
            {`${accountDetails.f_name} ${accountDetails.l_name}`}
          </p>

          <p>
            <strong>Email:</strong> {accountDetails.email}
          </p>
          <p>
            <strong>Phone:</strong> {accountDetails.phone}
          </p>
        </div>
      ) : (
        <div className="no-data">No account details found.</div>
      )}
    </div>
  );
};

export default Dashboard;
