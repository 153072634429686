import React, { useState, useEffect } from "react";
import "./Order.css";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      const token = localStorage.getItem("authToken");

      if (!token) {
        setError("No authentication token found");
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(
          "https://admin.aristoc.co.ug:9443/api/v1/customer/order/list",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch orders");
        }

        const data = await response.json();
        setOrders(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  if (loading) {
    return <div className="loading">Loading orders...</div>;
  }

  if (error) {
    return <div className="error">Error: {error}</div>;
  }

  return (
    <div className="orders-container">
      <h1 className="orders-title">Your Orders</h1>
      {orders && orders.length > 0 ? (
        <div className="table-container">
          <table className="orders-table">
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Date</th>
                <th>Status</th>
                <th>Total Amount</th>
                <th>Items</th>
                <th>Shipping Address</th>
                <th>Payment Method</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr key={order.id}>
                  <td>{order.id}</td>
                  <td>{new Date(order.updated_at).toLocaleDateString()}</td>
                  <td>{order.order_status}</td>
                  <td>UGX {order.order_amount}</td>
                  <td>
                    <ul>
                      {order.details.map((item, index) => (
                        <li key={index}>
                          {item.product_details.name} - UGX {item.price} x{" "}
                          {item.quantity}
                        </li>
                      ))}
                    </ul>
                  </td>
                  <td>
                    {order.delivery_address
                      ? order.delivery_address.address
                      : "N/A"}
                  </td>
                  <td>{order.payment_method}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="no-orders">You have no orders yet.</div>
      )}
    </div>
  );
};

export default Orders;
