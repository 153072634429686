import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ForgotPassword.css";
import TopBar from "../../components/TopBar";
import Footer from "../../components/Footer";

export default function ForgotPassword() {
  const [contact, setContact] = useState("");
  const [contactMethod, setContactMethod] = useState("email");
  const [notification, setNotification] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (contactMethod === "email") {
      if (!contact || !/\S+@\S+\.\S+/.test(contact)) {
        setNotification("Please enter a valid email address.");
        return;
      }
    } else if (contactMethod === "phone") {
      if (!contact || !/^\+?\d{10,15}$/.test(contact)) {
        setNotification("Please enter a valid phone number.");
        return;
      }
    }

    setIsLoading(true);
    setNotification("");

    const resetData = {
      email_or_phone: contact,
    };

    try {
      const response = await fetch(
        "https://admin.aristoc.co.ug:9443/api/v1/auth/forgot-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(resetData),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      const data = await response.json();

      if (data.success) {
        setNotification(
          "A password reset link has been sent to your email or phone."
        );
        setTimeout(() => navigate("/login"), 3000);
      } else {
        setNotification("An error occurred. Please try again later.");
      }
    } catch (error) {
      console.error("Error during password reset:", error);
      setNotification("An error occurred. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <TopBar />
      <div className="forgot-password-container">
        <h2>Forgot Your Password?</h2>
        <p>
          Please enter your{" "}
          {contactMethod === "email" ? "email address" : "phone number"} below,
          and we will send you a link to reset your password.
        </p>
        {notification && (
          <div className="forgot-password-notification">{notification}</div>
        )}

        <form onSubmit={handleSubmit}>
          <div className="forgot-password-input-group">
            <label htmlFor={contactMethod}>
              {contactMethod === "email" ? "Email Address" : "Phone Number"}
            </label>
            <input
              type={contactMethod === "email" ? "email" : "tel"}
              id={contactMethod}
              placeholder={
                contactMethod === "email"
                  ? "Enter your email address"
                  : "Enter your phone number"
              }
              value={contact}
              onChange={(e) => setContact(e.target.value)}
              required
            />
          </div>

          <div className="forgot-password-input-group">
            <label>How would you like to proceed?</label>
            <select
              value={contactMethod}
              onChange={(e) => setContactMethod(e.target.value)}
            >
              <option value="email">Email</option>
              <option value="phone">Phone</option>
            </select>
          </div>

          <button type="submit" disabled={isLoading}>
            {isLoading ? "Sending..." : "Send Reset Link"}
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
}
