import React from "react";
import "./FlashSaleModal.css";

const FlashSalesModal = ({ onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>This Site is Under Construction!!!</h2>
        <p>Please do not place an order, but feel free to make inquiries.</p>
        <button className="close-btn" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default FlashSalesModal;
