import React, { useState } from "react";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ProductSlider.css";

// Component to handle image loading with fallback
const ProductImage = ({ product }) => {
  const [imageSrc, setImageSrc] = useState(
    `https://admin.aristoc.co.ug:9443/product/${product.image[0]}`
  );

  const handleError = () => {
    // On error, try loading from the storage folder
    setImageSrc(
      `https://admin.aristoc.co.ug:9443/storage/product/${product.image[0]}`
    );
  };

  return (
    <img
      src={imageSrc}
      alt={product.name}
      onError={handleError} // If the image is not found in public, try the storage folder
    />
  );
};

const ProductSlider = ({ products, slidesToShow = 5 }) => {
  const navigate = useNavigate();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow,
    slidesToScroll: slidesToShow,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(slidesToShow, 3),
          slidesToScroll: Math.min(slidesToShow, 3),
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: Math.min(slidesToShow, 1),
          slidesToScroll: Math.min(slidesToShow, 1),
        },
      },
    ],
  };

  const handleProductClick = (productId) => {
    navigate(`/product-details/${productId}`);
  };

  return (
    <div className="slider-wrapper">
      <Slider {...settings}>
        {products.length > 0 ? (
          products.map((product) => (
            <div className="product-item" key={product.id}>
              <div
                onClick={() => handleProductClick(product.id)}
                role="button"
                style={{ cursor: "pointer" }}
                title={product.name}
              >
                <ProductImage product={product} />{" "}
                {/* Use the new component here */}
                <h4 className="product-title">{product.name}</h4>
                <p className="product-description">{product.description}</p>
                <button className="add-to-cart-button">Add To Cart</button>
              </div>
            </div>
          ))
        ) : (
          <div>Products Not Available</div>
        )}
      </Slider>
    </div>
  );
};

export default ProductSlider;
