import React, { useState } from "react";
import "./Gift.css";
import TopBar from "../../components/TopBar";
import Footer from "../../components/Footer";

export default function GiftCardPage() {
  const [redeemCode, setRedeemCode] = useState("");
  const [balance, setBalance] = useState(0);
  const [purchaseAmount, setPurchaseAmount] = useState("");
  const [customAmount, setCustomAmount] = useState("");
  const [contactMethod, setContactMethod] = useState("email"); // email or whatsapp
  const [contactInfo, setContactInfo] = useState("");
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [notification, setNotification] = useState("");

  const availableAmounts = [100000, 200000, 300000, 500000, 1000000]; // Available gift card amounts

  // Simulated function for redeeming gift cards
  const handleRedeemSubmit = (e) => {
    e.preventDefault();
    if (redeemCode === "VALIDCODE123") {
      setBalance(balance + 50); // Add $50 to balance
      setNotification("Gift Card redeemed successfully!");
      setTransactionHistory([
        ...transactionHistory,
        { type: "redeem", amount: 50, date: new Date().toLocaleString() },
      ]);
    } else {
      setNotification("Invalid gift card code.");
    }
    setRedeemCode("");
  };

  // Handle gift card purchase
  const handlePurchaseSubmit = (e) => {
    e.preventDefault();
    if (!purchaseAmount && !customAmount) {
      setNotification("Please select or enter an amount.");
      return;
    }

    const finalAmount = purchaseAmount || customAmount;
    if (finalAmount <= 0) {
      setNotification("Amount must be greater than zero.");
      return;
    }

    setBalance(balance + parseInt(finalAmount, 10));
    setNotification(`Successfully purchased a $${finalAmount} gift card.`);

    // Simulate sending gift card
    setTimeout(() => {
      sendGiftCard(finalAmount, contactMethod, contactInfo);
    }, 500);

    setTransactionHistory([
      ...transactionHistory,
      {
        type: "purchase",
        amount: parseInt(finalAmount, 10),
        date: new Date().toLocaleString(),
      },
    ]);
    setPurchaseAmount("");
    setCustomAmount("");
    setContactInfo("");
  };

  // Simulate sending gift card via email or WhatsApp
  const sendGiftCard = (amount, method, contact) => {
    const message = `You've received a $${amount} Gift Card! Use code: VALIDCODE123. Enjoy shopping with us!`;
    if (method === "email") {
      // Simulated email send (replace with API call to send email)
      console.log(`Sending gift card via email to ${contact}: ${message}`);
      setNotification(`Gift card sent to ${contact} via email.`);
    } else if (method === "whatsapp") {
      // Simulated WhatsApp send (replace with API call to send WhatsApp message)
      console.log(`Sending gift card via WhatsApp to ${contact}: ${message}`);
      setNotification(`Gift card sent to ${contact} via WhatsApp.`);
    }
  };

  return (
    <div>
      <TopBar />

      <div className="gift-card-page">
        <h1>Aristoc Gift Cards</h1>

        {/* Notification */}
        {notification && (
          <div className="gift-card-notification">{notification}</div>
        )}

        <div className="gift-card-container">
          {/* Gift Card Purchase Section */}
          <div className="gift-card-purchase-section">
            <h2>Buy a Gift Card</h2>
            <form onSubmit={handlePurchaseSubmit}>
              <label htmlFor="gift-card-amount">Select Amount:</label>
              <select
                id="gift-card-amount"
                value={purchaseAmount}
                onChange={(e) => setPurchaseAmount(e.target.value)}
                required
              >
                <option value="">Select an amount</option>
                {availableAmounts.map((amount, index) => (
                  <option key={index} value={amount}>
                    ${amount}
                  </option>
                ))}
                <option value="other">Other</option>
              </select>

              {/* Display the custom amount input if "Other" is selected */}
              {purchaseAmount === "other" && (
                <div className="gift-card-custom-amount">
                  <label htmlFor="gift-card-custom-amount">
                    Enter Custom Amount:
                  </label>
                  <input
                    type="number"
                    className="gift-input"
                    id="gift-card-custom-amount"
                    value={customAmount}
                    onChange={(e) => setCustomAmount(e.target.value)}
                    placeholder="Enter a custom amount"
                    min="1"
                  />
                </div>
              )}

              <div className="gift-card-contact-method">
                <label htmlFor="gift-card-contact-method">Send via:</label>
                <select
                  id="gift-card-contact-method"
                  value={contactMethod}
                  onChange={(e) => setContactMethod(e.target.value)}
                  required
                >
                  <option value="email">Email</option>
                  <option value="whatsapp">WhatsApp</option>
                </select>
              </div>

              <label htmlFor="gift-card-contact-info">
                Enter {contactMethod === "email" ? "Email" : "WhatsApp"}:
              </label>
              <input
                type={contactMethod === "email" ? "email" : "tel"}
                className="gift-input"
                id="gift-card-contact-info"
                value={contactInfo}
                onChange={(e) => setContactInfo(e.target.value)}
                required
                placeholder={
                  contactMethod === "email"
                    ? "Enter your email"
                    : "Enter your phone number"
                }
              />

              <button className="gift-button" type="submit">
                Purchase Gift Card
              </button>
            </form>
          </div>

          {/* Redeem Gift Card Section */}
          <div className="gift-card-redeem-section">
            <h2>Redeem Gift Card</h2>
            <form onSubmit={handleRedeemSubmit}>
              <label htmlFor="gift-card-redeem-code">
                Enter Gift Card Code:
              </label>
              <input
                type="text"
                id="gift-card-redeem-code"
                className="gift-input"
                value={redeemCode}
                onChange={(e) => setRedeemCode(e.target.value)}
                placeholder="Enter your gift card code"
                required
              />
              <button className="gift-button" type="submit">
                Redeem Code
              </button>

              <div className="gift-card-balance">
                <h3>Current Balance: ${balance}</h3>
              </div>
            </form>
          </div>
        </div>

        {/* Transaction History */}
        <div className="gift-card-transaction-history">
          <h3>Transaction History</h3>
          <ul>
            {transactionHistory.map((txn, index) => (
              <li key={index}>
                {txn.type === "redeem" ? "Redeemed" : "Purchased"} ${txn.amount}{" "}
                on {txn.date}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
}
