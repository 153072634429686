import React, { Component } from 'react'
import Promo from '../pages/HomeScreen/Promo/Promo'
import HeaderTop from '../pages/HomeScreen/HeaderTop/HeaderTop'
import HeaderMiddle from '../pages/HomeScreen/HeaderMiddle/HeaderMiddle'
import HeaderBottom from '../pages/HomeScreen/HeaderBottom/HeaderBottom'

export default class TopBar extends Component {
  render() {
    return (
      <div>
        <Promo/>
        <HeaderTop/>
        <HeaderMiddle/>
        <HeaderBottom/>
      </div>
    )
  }
}
