import React from "react";
import "./RefundPolicy.css";
import TopBar from "../../components/TopBar";
import Footer from "../../components/Footer";

const RefundPolicy = () => {
  return (
    <div>
      <TopBar />
      <div className="refund-policy">
        <h1>Refund Policy</h1>

        <section className="policy-section">
          <h2>Overview</h2>
          <p>
            At Aristoc, we are committed to providing our customers with the
            best shopping experience. If for any reason you are not satisfied
            with your purchase, we offer a straightforward refund and return
            policy to ensure peace of mind with every order.
          </p>
        </section>

        <section className="policy-section">
          <h2>Eligibility for Refund</h2>
          <p>
            To be eligible for a refund, the following conditions must be met:
          </p>
          <ul>
            <li>
              The item must be returned within 14 days from the date of
              delivery.
            </li>
            <li>
              The item must be unused, in its original packaging, and in the
              same condition that you received it.
            </li>
            <li>
              Proof of purchase (order number or receipt) must be provided.
            </li>
          </ul>
        </section>

        <section className="policy-section">
          <h2>How to Request a Refund</h2>
          <p>
            If you believe you are eligible for a refund, please follow these
            steps:
          </p>
          <ol>
            <li>
              Contact our customer support team at{" "}
              <a href="mailto:support@aristoc.co.ug">support@aristoc.co.ug</a>{" "}
              or call us at +256 708 695136.
            </li>
            <li>
              Provide your order number, a brief description of the issue, and
              any relevant photos (if applicable).
            </li>
            <li>
              Our team will review your request and guide you through the return
              process.
            </li>
          </ol>
        </section>

        <section className="policy-section">
          <h2>Refund Process</h2>
          <p>
            Once we have received the returned item and confirmed that it meets
            the eligibility criteria, we will process your refund. Refunds will
            be issued to the original payment method within 7-10 business days.
          </p>
          <p>
            Please note that shipping charges are non-refundable. If your order
            was shipped using a promotional discount or voucher, the value of
            the discount will be deducted from the refund amount.
          </p>
        </section>

        <section className="policy-section">
          <h2>Exceptions to the Refund Policy</h2>
          <p>
            Unfortunately, some items are not eligible for a refund. These
            include:
          </p>
          <ul>
            <li>Items that are damaged due to misuse or improper handling.</li>
            <li>Items that were custom-made or personalized.</li>
            <li>
              Items that have been used or opened (e.g., electronics, books,
              etc.).
            </li>
          </ul>
        </section>

        <section className="contact-section">
          <h2>Need Help?</h2>
          <p>
            If you have any questions about our refund policy or need
            assistance, please feel free to contact us at{" "}
            <a href="mailto:support@aristoc.co.ug">support@aristoc.co.ug</a> or
            call us at +256 708 695136.
          </p>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default RefundPolicy;
