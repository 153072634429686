import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import DenseTable from './DenseTable';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function FullWidthTabs({ choiceOptions }) {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ bgcolor: 'background.paper', width: '95%' }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          style={{ backgroundColor: '#EE1925' }}
        >
          <Tab label="Details" />
          <Tab label="Reviews" />
          <Tab label="Q&A" />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} dir={theme.direction}>
        <DenseTable choiceOptions={choiceOptions} />
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        No Reviews Yet
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
        Q&A
      </TabPanel>
    </Box>
  );
}
