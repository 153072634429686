import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './ProductImage.css';

const ProductImage = ({ product, imageIndex = 0, style, className, onLoad, onError, ...rest  }) => {
  const [imageSrc, setImageSrc] = useState(
    `https://admin.aristoc.co.ug:9443/product/${product.image[imageIndex]}`
  );

  const handleError = () => {
    // On error, try loading from the storage folder
    setImageSrc(
      `https://admin.aristoc.co.ug:9443/storage/product/${product.image[imageIndex]}`
    );
  };

  return (
    <img
      src={imageSrc}
      alt={product.name}
      onError={handleError} // If the image is not found in public, try the storage folder
      className={`product-image ${className}`} // Include custom classes if provided
      style={style} // Apply custom styles if provided
      onLoad={onLoad}
      {...rest}
    />
  );
};

ProductImage.propTypes = {
  product: PropTypes.shape({
    image: PropTypes.arrayOf(PropTypes.string).isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  imageIndex: PropTypes.number, // Index of the image to display
  style: PropTypes.object, // Optional inline styles
  className: PropTypes.string, // Optional class names
};

export default ProductImage;
