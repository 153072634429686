// Modal.js
import React from 'react';
import './Modal.css'; // Ensure you have styles for the modal

const Modal = ({ url, onClose }) => {
    if (!url) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <h2>Payment URL</h2>
                <p>Click the link below to complete the payment:</p>
                <a href={url} target="_blank" rel="noopener noreferrer">Complete Payment</a>
                <button className="close-modal-button" onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default Modal;
